import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-13a2e122"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "fence_dialog"
};
const _hoisted_2 = {
  class: "overflow-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_FenceMap = _resolveComponent("FenceMap");
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_TypeSelect = _resolveComponent("TypeSelect");
  const _component_el_time_picker = _resolveComponent("el-time-picker");
  const _component_Icon = _resolveComponent("Icon");
  const _component_el_form = _resolveComponent("el-form");
  const _component_Eldialog = _resolveComponent("Eldialog");
  return _openBlock(), _createBlock(_component_Eldialog, _mergeProps(_ctx.$attrs, {
    visible: _ctx.show,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => _ctx.show = $event),
    title: _ctx.dialogTitle,
    draggable: "",
    dialogTop: "7vh",
    onConfirmOk: _ctx.debounceConfirm,
    width: "80vw"
  }), {
    form: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_FenceMap, {
      ref: "refMap",
      class: "map_outer",
      mapZoom: _ctx.mapZoom,
      mapCenter: _ctx.mapCenter,
      onDrawFenceOk: _ctx.drawFenceOk,
      onClearMap: _ctx.clearMap
    }, null, 8, ["mapZoom", "mapCenter", "onDrawFenceOk", "onClearMap"]), _createVNode(_component_el_form, {
      class: "map_form",
      ref: "ruleForm",
      rules: _ctx.rules,
      model: _ctx.formData,
      "label-width": "98px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_alert, {
        style: {
          "margin-bottom": "10px"
        },
        title: "请先在地图上绘制围栏, 再填写围栏信息",
        type: "warning",
        closable: false
      }), _createVNode(_component_el_form_item, {
        label: "围栏名称",
        prop: "pathName"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          disabled: _ctx.formData.useType === 3,
          modelValue: _ctx.formData.pathName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.formData.pathName = $event),
          placeholder: "请输入围栏名称"
        }, null, 8, ["disabled", "modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "备注",
        prop: "remark"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.formData.remark,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.formData.remark = $event),
          maxlength: "200",
          disabled: _ctx.formData.useType === 3,
          "show-word-limit": "",
          type: "textarea"
        }, null, 8, ["modelValue", "disabled"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: "围栏类型",
        prop: "type"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fenceTypeOptions, (type, index) => {
          return _openBlock(), _createBlock(_component_el_radio, {
            disabled: _ctx.isUpdate || index === 2,
            modelValue: _ctx.formData.useType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.formData.useType = $event),
            key: type.value,
            label: type.value
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(type.label), 1)]),
            _: 2
          }, 1032, ["disabled", "modelValue", "label"]);
        }), 128))]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: ['告警类型', '考勤类型', '危险区域'][_ctx.formData.useType - 1],
        prop: "alarmType"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.useType !== 2 ? _ctx.alarmTypeOptions : _ctx.attendanceTypeOptions, type => {
          return _openBlock(), _createBlock(_component_el_radio, {
            disabled: _ctx.formData.useType === 3,
            modelValue: _ctx.formData.alarmType,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.formData.alarmType = $event),
            key: type.value,
            label: type.value
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(type.label), 1)]),
            _: 2
          }, 1032, ["disabled", "modelValue", "label"]);
        }), 128))]),
        _: 1
      }, 8, ["label"]), _ctx.formData.useType == 2 ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0,
        label: "考勤日期",
        prop: "date"
      }, {
        default: _withCtx(() => [_createVNode(_component_TypeSelect, {
          style: {
            "width": "100%"
          },
          modelValue: _ctx.formData.date,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.formData.date = $event),
          type: "weekType",
          multiple: true
        }, null, 8, ["modelValue"])]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.formData.useType == 2 && _ctx.formData.attTime ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 1,
        label: "时间段",
        prop: "attTime"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.attTime, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: "period-list"
          }, [_createVNode(_component_el_time_picker, {
            style: {
              "width": "100px"
            },
            clearable: false,
            modelValue: item.start,
            "onUpdate:modelValue": $event => item.start = $event,
            format: "HH:mm"
          }, null, 8, ["modelValue", "onUpdate:modelValue"]), _createTextVNode(" 至 "), _createVNode(_component_el_time_picker, {
            style: {
              "width": "100px"
            },
            clearable: false,
            modelValue: item.end,
            "onUpdate:modelValue": $event => item.end = $event,
            format: "HH:mm"
          }, null, 8, ["modelValue", "onUpdate:modelValue"]), _ctx.formData.attTime.length > 1 ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            class: "remove-icon",
            onClick: $event => _ctx.debounceRemove(index),
            name: "DeleteFilled"
          }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.formData.attTime.length - 1 == index ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: "add-icon",
            onClick: _ctx.debounceAdd,
            name: "CirclePlus"
          }, null, 8, ["onClick"])) : _createCommentVNode("", true)]);
        }), 128))])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["rules", "model"])])]),
    footer: _withCtx(() => []),
    _: 1
  }, 16, ["visible", "title", "onConfirmOk"]);
}