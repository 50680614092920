import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, watch, unref, defineAsyncComponent, nextTick } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElRadio, ElCheckboxGroup, ElCheckbox, ElAlert, ElTimePicker } from 'element-plus';
import { fenceTypeOptions, alarmTypeOptions, drawTypeOptions, attendanceTypeOptions, dateTypeOptions } from './options';
import api from '@/api/safetyFence';
import { useMessage } from '@/hooks/web/useMessage';
import { isNullOrUnDef } from '@/utils/is';
import sleep from '@/utils/sleep';
import { useDebounce } from '@/hooks/core/useDebounce';
import dayjs from "dayjs";
export default defineComponent({
  name: 'FenceDialog',
  components: {
    Eldialog,
    ElRadio,
    ElCheckboxGroup,
    ElCheckbox,
    ElAlert,
    ElTimePicker,
    FenceMap: defineAsyncComponent(() => import('./FenceMap.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  emits: ['update:visible', 'ok'],
  props: {
    info: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    mapZoom: {
      type: Number,
      default: 12
    },
    mapCenter: {
      type: Object,
      default: null
    }
  },
  setup(props, {
    emit
  }) {
    const messageBox = useMessage();
    const refData = reactive({
      refMap: null,
      ruleForm: null,
      formData: null,
      rules: {
        pathName: [{
          required: true,
          message: '请输入围栏名称',
          type: 'string',
          trigger: 'blur'
        }, {
          min: 1,
          max: 20,
          message: '长度在 1 到 20 个字符',
          trigger: 'blur'
        }, {
          pattern: /^[\u4E00-\u9FA5a-zA-Z0-9_]*$/,
          message: '围栏名称不能带有特殊符号'
        }],
        type: [{
          required: true,
          message: '请画围栏',
          type: 'number',
          trigger: 'blur'
        }],
        useType: [{
          required: true,
          message: '请选择围栏类型',
          type: 'number',
          trigger: 'blur'
        }],
        alarmType: [{
          required: true,
          message: '请选择提醒类型',
          type: 'number',
          trigger: 'blur'
        }],
        attTime: [{
          required: true,
          message: '请至少添加一个时间段',
          type: 'array',
          trigger: 'change'
        }],
        remark: [{
          required: false,
          message: '备注',
          type: 'string',
          trigger: 'change'
        }]
      }
    });
    const isUpdate = computed(() => {
      var _props$info;
      return !!((_props$info = props.info) !== null && _props$info !== void 0 && _props$info.pathId);
    });
    const dialogTitle = computed(() => unref(isUpdate) ? '修改围栏' : '新增围栏');
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    watch(() => props.visible, async visible => {
      if (!visible) return;
      init();
      if (unref(isUpdate) && props.info !== null) {
        await sleep(800);
        renderFence();
      }
    });
    function init() {
      refData.formData = {
        attTime: [{
          start: new Date(dayjs().format('YYYY-MM-DD 00:00')),
          end: new Date(dayjs().format('YYYY-MM-DD 23:59'))
        }],
        date: 0,
        pathName: '',
        type: 0,
        useType: 1,
        alarmType: 1,
        remark: '',
        createType: 1,
        centerLat: undefined,
        centerLon: undefined,
        radius: undefined,
        pointList: [],
        ...(props.info || {})
      };
      console.log(JSON.stringify(props.info));
      console.log(JSON.stringify(refData.formData));
    }
    async function confirm() {
      if (refData.formData.useType === 3) {
        return emit('update:visible', false);
      }
      if (!(await validate())) return;
      if (refData.formData.pointList.length === 0 && refData.formData.type === 2) {
        return messageBox.warning('请画围栏');
      }
      try {
        let result;
        if (refData.formData.useType == 2) {
          refData.formData.attTime.map((item, index) => {
            if (dayjs(item.start).format('HH:mm') > dayjs(item.end).format('HH:mm')) throw `第${index + 1}行时间段结束时间不能小于开始时间段`;
            if (index && dayjs(item.start).format('HH:mm') < dayjs(refData.formData.attTime[index - 1].end).format('HH:mm')) throw `第${index + 1}行时间段开始时间不能小于第${index}行结束时间段`;
          });
        }
        const params = {
          ...refData.formData,
          attTime: refData.formData.attTime.map(item => {
            return {
              attStart: dayjs(item.start).format('HH:mm'),
              attEnd: dayjs(item.end).format('HH:mm'),
              dateTime: refData.formData.date.length ? refData.formData.date.join(',') : '0'
            };
          })
        };
        delete params.date;
        if (unref(isUpdate)) {
          result = await api.update(params);
        } else {
          result = await api.add(params);
        }
        if (isNullOrUnDef(result)) return;
        if (result.code === 0) {
          emit('ok');
          show.value = false;
        }
        if (result.code === 0) {
          return messageBox.success(result.msg);
        }
        messageBox.warning(result.msg);
      } catch (error) {
        messageBox.error(error);
      }
    }
    async function validate() {
      try {
        const valid = await refData.ruleForm.validate();
        return valid;
      } catch (error) {
        //
      }
      return false;
    }
    function drawFenceOk(info) {
      if (info.type === 1) {
        // 圆形围栏
        refData.formData.type = 1;
        refData.formData.radius = info.radius;
        refData.formData.centerLat = info.centerLat;
        refData.formData.centerLon = info.centerLon;
      } else if (info.type === 2) {
        // 多边形围栏
        refData.formData.type = 2;
        console.log(info.pointList, 'info.pointList');
        refData.formData.pointList = info.pointList;
      }
    }
    async function renderFence() {
      if (unref(isUpdate)) {
        refData.refMap.setMapZoom(16);
        await nextTick();
        if (props.info.type === 1) {
          // 圆形围栏
          refData.refMap.renderCircle({
            lat: props.info.centerLat,
            lon: props.info.centerLon
          }, props.info.radius);
        } else if (props.info.type === 2) {
          // 多边形围栏
          refData.refMap.renderPolygon(props.info.pointList);
        }
      }
    }
    function clearMap() {
      refData.formData.pointList = [];
    }
    const [debounceConfirm] = useDebounce(confirm, 300);
    //添加时间段
    function handleAdd() {
      refData.formData.attTime.push({
        start: dayjs(new Date()).format('YYYY-MM-DD 00:00'),
        end: dayjs(new Date()).format('YYYY-MM-DD 23:59')
      });
    }
    //删除时间段
    function handleRemove(active) {
      refData.formData.attTime = refData.formData.attTime.filter((item, index) => active != index);
    }
    init();
    const [debounceAdd] = useDebounce(handleAdd, 300);
    const [debounceRemove] = useDebounce(handleRemove, 300);
    return {
      ...toRefs(refData),
      dialogTitle,
      show,
      debounceConfirm,
      fenceTypeOptions,
      alarmTypeOptions,
      attendanceTypeOptions,
      dateTypeOptions,
      drawTypeOptions,
      drawFenceOk,
      clearMap,
      debounceAdd,
      debounceRemove,
      isUpdate
    };
  }
});